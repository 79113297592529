<template>
  <div>
    <h1 class="display-5 mb-4">
      How to connect Surfe to Hubspot
    </h1>

    <p class="text-muted mb-2">
      June 12, 2021
    </p>

    <p>
      Welcome to this onboarding guide.<br>
      On this page, you'll find information to help you connect Surfe to your Hubspot CRM.
    </p>

    <h1 class="mt-3">
      Check your user permissions
    </h1>

    <p>
      Every Surfe user must be granted enough permissions before being able to use Surfe.
    </p>

    <b-alert
      show
      variant="primary"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-1"
          icon="InfoIcon"
        />
        <span class="ml-1">You may have to ask your Hubspot admin to change these settings for you.</span>
      </div>
    </b-alert>

    <p>
      Go to <b>Settings > Users & Teams</b> and click on the user's line. A panel will slide in from the right.
    </p>

    <p>Under <b>Account > Settings</b>, make sure <b>App Marketplace Access</b> is enabled.</p>

    <b-img
      fluid
      :src="require('@/assets/images/support/hubspot_marketplace.png')"
      style="width:100%"
      class="my-2"
    />

    <p>Under <b>CRM > CRM tools</b>, make sure <b>Edit property settings</b> is enabled (this option can be switched off after the user successfully connected to Surfe).</p>

    <b-img
      fluid
      :src="require('@/assets/images/support/hubspot_property.png')"
      style="width:100%"
      class="my-2"
    />

    <h1 class="mt-3">
      Grant Surfe access to your Hubspot account
    </h1>

    <p>
      Once the necessary permissions are live, you can follow the onboarding steps from the Surfe welcome panel, and grant Surfe access to Hubspot.<br>
      You're all set!
    </p>
  </div>
</template>

<script>
import { BAlert, BImg } from 'bootstrap-vue'

export default {
  components: {
    BAlert, BImg,
  },
}
</script>

<style scoped>
p {
  padding: 10px 0;
  font-size: 16px;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
}
</style>
